import $ from 'jquery'

$(document).on('turbolinks:load', ()=>{
  if($("#user-form").length > 0){
    setRoleChange();
  }
})

function setRoleChange(){  
  if($("#user_role").length > 0){
    let role = $("#user_role")[0].value;
    showOrHideRoleInputs(role);
  }
  $("#user_role").on("change", ({ target })=>{
    showOrHideRoleInputs(target.value);
  });
}

function showOrHideRoleInputs(current_role){
  // si es cliente escondo la sel de cliente y muestro la sel de sucursal
  let hide_group = "#branch-form-group";
  let non_req_input = "#user_branch_id";
  let show_group = "#client-form-group";
  let req_input = "#user_client_id";
  // si no es un cliente es al revez que el de arriba
  if(current_role != 4){
    hide_group = "#client-form-group";
    non_req_input = "#user_client_id";
    show_group = "#branch-form-group";
    req_input = "#user_branch_id";
  } 
  // seteo cuál es requerido
  $(non_req_input)[0].required = false;
  $(non_req_input)[0].disabled = true;
  $(req_input)[0].required = true;
  $(req_input)[0].disabled = false;
  // escondo el primero y muestro el sig
  $(hide_group).hide(300, ()=>{
    $(show_group).show(300)
  });
  
}