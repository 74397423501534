import AutoNumeric from 'autonumeric'
import $ from 'jquery'

$(document).on("turbolinks:load", ()=>{
  setAutoNumeric();
  hideAlerts();
  setTooltips();
  ignoreEmpties();
})

function setAutoNumeric(){
  $('.auto-num').each((num, ele)=>{  
    new AutoNumeric(ele, ele.value, { 
      currencySymbol: '',
      digitGroupSeparator: '.',
      decimalCharacter: ',',
      decimalPlaces: 0,
      unformatOnSubmit: true
    });
  })
}

function hideAlerts(){
  setTimeout(()=>{
    $(".alert").toggle(750);
  }, 5000);
}

function setTooltips(){
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
}

function ignoreEmpties(){
  $('.form-ignore-empty').submit(function () {
    $(this)
      .find('input[name]')
      .filter(function () {
          return !this.value;
      })
      .prop('name', '');
    $(this)
      .find('select[name]')
      .filter(function () {
          return !this.value;
      })
      .prop('name', '');
});
}