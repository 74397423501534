import $ from 'jquery'
import { notify, numberWithCommas } from "./utils";

$(document).on("turbolinks:load", ()=>{
  $(".list-client-freights-btn").on("click", openReport)
  $("#client_freight_destination_code").on("change", searchDestinationByCode)
  $("#client_freight_receipt_map_url").on('change', completeOpenMapBtn)
  $("#client_freight_package_type_id").on('change', onChangePackageType)

  $("#process_client_freights").on("click", processClientFreights)
})

function openReport({ target }){
  let url = window.location.href.replace("client_freights", "client_freights.pdf") 
  if(target.dataset.asTicket == "true"){
    if(url.includes("?")) url = url + "&as_ticket=1"
    else url = url + "?as_ticket=1"
  }
  window.open(url);
}

function searchDestinationByCode({ target }){
  let code = target.value;
  $.ajax(`/address_book_entries.json?by_code=${code}`, {    
    method: 'GET',
    success: (response)=>{
      target.value = "";
      if (response.length > 0){
        let bookEntry = response[0];
        $("#client_freight_destination")[0].value = bookEntry.name;
        $("#client_freight_destination_city_id")[0].value = bookEntry.location_id;
        $("#client_freight_destination_address")[0].value = bookEntry.address;
        $("#client_freight_destination_number")[0].value = bookEntry.phone_number;
      } else {
        alertify.warning("No existe una dirección con ese código");
        $("#client_freight_destination")[0].value = "";
        $("#client_freight_destination_city_id")[0].value = "";
        $("#client_freight_destination_address")[0].value = "";
        $("#client_freight_destination_number")[0].value = "";
      }
    }
  })
}

function completeOpenMapBtn({ target }){
  let url = target.value;
  $("#open-map-btn")[0].href = url;
}

function onChangePackageType({ target }){
  let package_type_code = target.value;
  if (package_type_code != ""){
    $.ajax(`/package_types/${package_type_code}.json`, {
      method: 'GET',
      success: onReceivedPackageType,
      error: onFailurePackageType
    })
  }
}

function onReceivedPackageType(response){
  $("#package_type_name")[0].value = `${response.name} (${numberWithCommas(response.price)}Gs)`
}

function onFailurePackageType({ status }, string_error){
  if (status === 404){
    $("#client_freight_package_type_id")[0].value = "";
    $("#package_type_name")[0].value = "";
    notify("El tipo de paquete elegido no existe", "error");
  }
}

function processClientFreights(event){
  let current_url = window.location.href;
  if(current_url.includes("client_freights")){
    if(!current_url.includes("?")){
      alert("Primero aplique los filtros a su búsqueda")
    } else {
      current_url = current_url.replace("client_freights", "client_freights/parse_to_freight");
    }
  }
  window.open(current_url, '_self');
}